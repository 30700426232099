import React, { ReactElement } from 'react'
import { Helmet } from 'react-helmet'
import { COLOR } from '@farewill/ui/tokens'
import { CLOUDINARY_ROOT_PATH } from '@farewill/ui/components/Image/constants'
import { useRecordSplitPageview } from 'lib/hooks/useRecordSplitPageview'

import { FF_FUNERAL_PLANS_TV_AD_ENABLED } from 'config'
import DefaultLayout from 'layouts/DefaultLayout'
import JSONLD from 'components/JSONLD'
import FAREWILL_JSONLD from 'lib/json-ld/farewill'
import useContentfulLandingPageRedirect from './contentfulRedirectHook'
import Content from './Content'

const HERO_IMAGE_PATH = FF_FUNERAL_PLANS_TV_AD_ENABLED
  ? `${CLOUDINARY_ROOT_PATH}/c_scale,f_auto,q_auto,w_500/illustrations/family-at-the-table.png`
  : `${CLOUDINARY_ROOT_PATH}/c_scale,f_auto,q_auto,w_500/illustrations/tv-homepage-hero`

const HomePage = ({
  pageContext,
}: {
  pageContext: {
    partnerSlugMap: string[]
  }
}): ReactElement => {
  useContentfulLandingPageRedirect(pageContext.partnerSlugMap)
  useRecordSplitPageview('homepage_page_view', undefined, { source: 'direct' })

  return (
    <>
      <Helmet>
        <link rel="preload" href={HERO_IMAGE_PATH} as="image" />
      </Helmet>
      <JSONLD data={FAREWILL_JSONLD} />
      <DefaultLayout
        title="Wills And Probate Experts | The Simpler Cremation Service"
        description="Write a legal will in 15 minutes. Apply for probate or arrange a cremation over the phone."
        headerColor={COLOR.ACCENT.PRIMARY}
        headerShowLoginLink
      >
        <Content />
      </DefaultLayout>
    </>
  )
}

export default HomePage
